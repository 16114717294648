import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Parallax } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import { StaticImage } from "gatsby-plugin-image";

SwiperCore.use([Pagination, Parallax]);

const Testimonials = (testimonials) => {
  const [load, setLoad] = useState(false);
  const paginationRef = React.useRef(null);

  const [testimonialList, setTestimonialList] = useState(null);

  const _resolveTestimonials = (data) => {
    let tempData = [];
    if (data && data !== "") {
      data.forEach((testimonial) => {
        if (testimonial.node.clientName !== "testing") {
          tempData.push(testimonial);
        }
      });
    }
    return tempData;
  };

  useEffect(() => {
    const resolvedData = _resolveTestimonials(testimonials);
    setTestimonialList(resolvedData);
  }, [testimonials]);

  useEffect(() => {
    setLoad(true);
  }, [load]);

  return (
    <section className="app-testim section-padding bg-gray">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-10">
            <div className="s-head text-center mb-80">
              <h6 className="stit mb-30">
                <span className="left"></span> Clients Feedback
                <span className="right"></span>
              </h6>
              <h2>Valuable Clients Feedback About Our Services</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="swiper-container">
              {load ? (
                <Swiper
                  slidesPerView={3}
                  spaceBetween={30}
                  speed={1000}
                  loop={false}
                  pagination={{
                    clickable: true,
                    el: paginationRef.current,
                  }}
                  className="swiper-wrapper"
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    767: {
                      slidesPerView: 2,
                      centeredSlides: false,
                    },
                    991: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {testimonialList.map((item, i) => {
                    return (
                      <SwiperSlide className="swiper-slide" key={i}>
                        <div className="item">
                          <div className="icon mb-50">
                            <StaticImage
                              src="../../../static/img/mobile-app/qoute.png"
                              alt=""
                            />
                          </div>
                          <div className="text">
                            <p>{item.node.clientTestimonial}</p>
                          </div>
                          <div className="info">
                            <div className="img">
                              <img loading ="lazy"
                                src={item.node.clientImage.guid}
                                alt={item.node.clientName}
                                   
                              />
                            </div>
                            <div className="cont">
                              <h6 className="mb-10">{item.node.clientName}</h6>
                              <span>{item.node.clientDesignation}</span>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              ) : (
                ""
              )}
              <div ref={paginationRef} className="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
