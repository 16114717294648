import React, { useEffect, useState } from "react";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import ServicesIntroduction from "components/Services-introduction";
import ServicesDescription from "components/Services-description";
import Services6 from "components/Services6/services6";
import ProjectDate from "data/project-details2.json";
import DarkTheme from "layouts/Dark";
import Faq from "components/Faq/faq";
import CallToAction from "components/Call-to-action/call-to-action";
import Works2 from "components/Works2/works2";
import Blogs4 from "components/blogs/Blogs4/blogs4";
import Testimonials from "components/Testimonials/testimonials";
import ParticalsOverlay from "components/Particals-Overlay/ParticalsOverlay";
import SEO from "components/seo/seo";
import Services8 from "components/Services8/services8";
import SContactForm from "components/s-contact-form/s-contact-form";
import { useStaticQuery, graphql } from "gatsby";
import LoadingScreen from "components/Loading-Screen";
import { GatsbyImage } from "gatsby-plugin-image";

const ProjectDetails2Dark = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allWpService {
        nodes {
          altText
          title
          subTitle
          slug
          description
          serviceIntroHead
          serviceIntroDetail
          servicesTypesHead
          servicesTypesTagline
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          extraContentDetail
          aboutServiceImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          aboutService
          serviceImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          technologiesData {
            nodes {
              slug
              title
            }
          }
          caseStudiesDetail
          caseStudiesHead
          caseStudies {
            nodes {
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              projectName
              shortDescription
              slug
            }
          }
          aprroachHead
          approachDetail
          processForService {
            nodes {
              processDetail
              processHead
            }
          }
          technologiesForService {
            nodes {
              title
              slug
              technologyIconForService {
                mediaItemUrl
              }
              technologyDescription
            }
          }
          faqs {
            nodes {
              faqDescription
              faqTitle
            }
          }
        }
      }
    }
  `);

  const service = data.allWpService.nodes;
  const technologiesData = props.pageContext.data;

  const liveUrl =
    typeof window !== "undefined" ? new URL(window.location.href) : null;

  const queryParameters = liveUrl ? liveUrl.searchParams : null;
  const selectedServiceFromURL = queryParameters
    ? queryParameters.get("selectedService")
    : null;
  const selectedTechnologyFromURL = queryParameters
    ? queryParameters.get("selectedTechnology")
    : null;

  const [selectedService, setSelectedService] = useState(
    props.location.state
      ? props.location.state.selectedService
      : selectedServiceFromURL
  );
  const [selectedTechnology, setSelectedTechnology] = useState(
    props.location.state
      ? props.location.state.selectedTechnology
      : selectedTechnologyFromURL
  );

  const [isSubService, setIsSubService] = useState(false);
  useEffect(() => {});
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const MainContent = React.useRef(null);
  const fixedHeader = React.useRef(null);
  const [theme, setTheme] = useState("Dark");

  useEffect(() => {
    const pathname = liveUrl ? liveUrl.pathname : "";
    const pathSegments = pathname
      .split("/")
      .filter((segment) => segment !== "");

    const extractedService = pathSegments[1]; // Change this index based on your URL structure
    const extractedTechnology = pathSegments[2]; // Change this index based on your URL structure

    if (!selectedService && extractedService) {
      setSelectedService(extractedService);
    }

    if (!selectedTechnology && extractedTechnology) {
      setSelectedTechnology(extractedTechnology);
    }

    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });

    setTimeout(() => {
      if (fixedHeader.current) {
        var slidHeight = fixedHeader.current.offsetHeight;
      }
      if (MainContent.current) {
        MainContent.current.style.marginTop = slidHeight + "px";
      }
    }, 1000);

    setIsSubService(pathSegments.length > 2);
  }, [
    selectedService,
    liveUrl,
    selectedTechnology,
    setIsSubService,
    fixedHeader,
    navbarRef,
  ]);

  const themeModeChange = (themeMode) => {
    let bodyEl = document.querySelector("body");
    if (themeMode == "Dark") {
      setTheme("themeL");
      bodyEl.classList.add("themeL");
      localStorage.setItem("theme", "themeL");
    } else {
      setTheme("Dark");
      bodyEl.classList.remove("themeL");
      localStorage.removeItem("theme");
    }
  };

  return (
    <DarkTheme>
      <LoadingScreen/>
      <Navbar
        nr={navbarRef}
        lr={logoRef}
        theme={theme}
        themeModeChange={themeModeChange}
      />

      {selectedService && !isSubService && (
        <>
          {service.map((singleService) =>
            singleService.slug === selectedService ? (
              
              <React.Fragment key={singleService.slug}>
                <div className="container">
                  <div className="service-head-container">
                    <div className="service-head-img">
                      <GatsbyImage loading ="lazy"
                        image={singleService.serviceImage.localFile.childImageSharp.gatsbyImageData}
                        alt={singleService.altText}
                      />
                    </div>
                    <div className="head-details">
                      <h1 className="animate-charcter"><strong>{singleService.title} Services</strong></h1>
                      <p>{singleService.subTitle}</p>
                    </div>
                  </div>
                </div>

                <ParticalsOverlay />
                <div ref={MainContent} className="main-content">
                  <Services8 servicesData={[singleService]} props={props} />
                  <SContactForm servicesData={[singleService]} props={props}/>
                  <Footer />
                </div>
              </React.Fragment>
            ) : null
          )}
        </>
      )}

      {selectedTechnology && isSubService && (
        <>
          <header
            ref={fixedHeader}
            className="works-header fixed-slider hfixd valign sub-bg custom-background"
            style={{
              backgroundImage: `url(${
                technologiesData &&
                technologiesData?.serviceHeroImage &&
                technologiesData?.serviceHeroImage?.guid
                  ? technologiesData?.serviceHeroImage?.guid
                  : ProjectDate.projectHeaderImage
              })`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <ParticalsOverlay />
            <div className="circle-bg">
              <div className="circle-color fixed">
                <div className="gradient-circle"></div>
                <div className="gradient-circle two"></div>
              </div>
            </div>
            <div className="subservice-container container">
              <div className="row">
                <div className="col-lg-7 col-md-9">
                  <div className="cont">
                    <h1 className="animate-charcter">{technologiesData?.title} Company</h1>
                    <p className="service-desc mt-3">
                      {technologiesData?.titleSlogan}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div ref={MainContent} className="main-content custom-margin">
            <ServicesIntroduction projectIntroductionData={technologiesData} />
            <ServicesDescription projectStrategyData={technologiesData} />

            <Services6 serviceTechnologies={technologiesData} />
            <Faq faqs={technologiesData} />

            {technologiesData.testimonials.edges.length > 1 && (
              <Testimonials testimonialsData={technologiesData.testimonials} />
            )}

            {technologiesData.subServices?.edges.length > 0 && (
              <Blogs4 subServices={technologiesData} />
            )}

            {technologiesData.recentProjects && (
              <Works2 Projects={technologiesData} />
            )}

            <CallToAction />

            <Footer />
          </div>
        </>
      )}
    </DarkTheme>
  );
};

export const Head = (props) => {
  const seoData = props.pageContext.data;
  const dynamicMetaTitle = seoData?.ssMetaTitle;
  const dynamicMetaDescription = seoData?.ssMetaDescription;
  const liveUrl = typeof window !== "undefined" ? window.location.href : "";

  return (
    <>
      <SEO
        canonical={liveUrl}
        title={dynamicMetaTitle}
        description={dynamicMetaDescription}
      />
      <link
        rel=""
        href="https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap"
      />
    </>
  );
};

export default ProjectDetails2Dark;
